<template>
  <div xmlns="http://www.w3.org/1999/xhtml" role="main" style="height: 859px;">
    <div role="application" class="nontest" data-section="true" data-sectionType="confirmDetails">
      <div class="box1">
        <h1 class="title hasicon">
          <img :src="require('@/assets/images/main/userCheck.png')" alt="" width="48" height="48"/>
          Confirm your details</h1>
        <div class="content">
          <table class="details">
            <tr>
              <th>Name:</th>
              <td candidate-data="name"></td>
            </tr>
            <tr>
              <th>Date of birth:</th>
              <td candidate-data="dob"></td>
            </tr>
            <tr>
              <th>Candidate number:</th>
              <td candidate-data="id"></td>
            </tr>
          </table>
          <p class="info">If your details are not correct, please inform the invigilator.</p>
          <div class="textCentre">
            <input type="submit" data-confirm="1" value="My details are correct" class="button" @click="$router.push({ name: targetTest })"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CandidateConfirm',
  props: {
    targetTest: {
      type: String,
      required: true
    }
  }
}
</script>
