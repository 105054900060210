<template>
  <CandidateConfirm targetTest="apps-academic-listening-instruction"></CandidateConfirm>
</template>

<script>
import CandidateConfirm from "@/views/components/CandidateConfirm";
export default {
  name: "ConfirmInformation",
  components: {CandidateConfirm}
}
</script>

<style scoped>

</style>
